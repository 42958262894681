<template>
  <div class="form">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px">
      <el-form-item label="姓名">
        <el-input placeholder="请输入姓名" v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input placeholder="请输入手机号" disabled v-model="ruleForm.mobile"></el-input>
      </el-form-item>
      <el-form-item label="性别">
        <el-select v-model="ruleForm.sex" placeholder="请选择性别">
          <el-option label="男" :value="1"></el-option>
          <el-option label="女" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="证件类型">
        <el-select v-model="ruleForm.card_type" placeholder="请选择证件类型">
          <el-option :label="item" :value="item" v-for="(item, index) in info.card_type" :key="index"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="证件号码">
        <el-input placeholder="请输入证件号码" v-model="ruleForm.id_card"></el-input>
      </el-form-item>
      <el-form-item label="出生日期">
        <el-date-picker
          type="date"
          placeholder="选择出生日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          v-model="ruleForm.birthday"
          style="width: 100%"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="衣服尺码">
        <el-select v-model="ruleForm.size" placeholder="请选择尺码">
          <el-option :label="item" :value="item" v-for="(item, index) in info.sizes" :key="index"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="紧急联系人">
        <el-input placeholder="请输入紧急联系人" v-model="ruleForm.contacts"></el-input>
      </el-form-item>
      <el-form-item label="紧急联系人电话">
        <el-input placeholder="请输入紧急联系人电话" v-model="ruleForm.contact_no"></el-input>
      </el-form-item>
      <el-form-item label="血型">
        <el-select v-model="ruleForm.blood_type" placeholder="请选择血型">
          <el-option :label="item" :value="item" v-for="(item, index) in info.blood" :key="index"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { objectKeys } from '@/utils/tools.js'
import { phoneReg, carIdReg } from '@/utils/regular.js'
import { infoData, getInfo } from '@/service/api/user'
const validatePhone = (rule, value, callback) => {
  phoneReg(value)
    .then(() => {
      callback()
    })
    .catch((err) => {
      return callback(new Error(err))
    })
}
const validateCardId = (rule, value, callback) => {
  carIdReg(value)
    .then(() => {
      callback()
    })
    .catch((err) => {
      return callback(new Error(err))
    })
}
export default {
  props: ['isReset', 'isSubmit'],
  data() {
    return {
      ruleForm: {
        // 性别 （1男2女）
        sex: '',
        // 尺码
        size: '',
        // 姓名
        name: '',
        // 手机号
        mobile: '',
        // 身份证
        id_card: '',
        // 紧急联系人
        contacts: '',
        // 出生日期
        birthday: '',
        // 证件类型
        card_type: '',
        // 血型
        blood_type: '',
        // 紧急联系人电话
        contact_no: ''
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        id_card: [
          { required: true, message: '请输入身份证', trigger: 'blur' },
          { validator: validateCardId, trigger: 'blur' }
        ],
        contact_no: [
          { required: true, message: '请输入紧急联系人电话', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        size: [{ required: true, message: '请选择尺码', trigger: 'blur' }],
        blood_type: [{ required: true, message: '请选择血型', trigger: 'blur' }],
        card_type: [{ required: true, message: '请选择身份证', trigger: 'blur' }],
        contacts: [{ required: true, message: '请输入紧急联系人', trigger: 'blur' }],
        birthday: [{ required: true, message: '请选择出生日期', trigger: 'blur' }]
      },
      info: ''
    }
  },

  mounted() {
    this.infoData()
    this.getInfo()
  },

  methods: {
    // 表单数据
    async infoData() {
      const res = await infoData()
      this.info = res.msg
    },
    // 个人信息
    async getInfo() {
      const res = await getInfo()
      objectKeys(res.msg, this.ruleForm)
    },

    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit('submitForm', this.ruleForm)
        } else {
          return this.$emit('submitForm', {})
        }
      })
    },
    resetForm() {
      this.$emit('resetForm', true)
      this.$refs.ruleForm.resetFields()
    }
  },
  watch: {
    isSubmit(val) {
      if (val) {
        console.log('11')
        this.submitForm()
      }
    },
    isReset(val) {
      if (val) {
        this.resetForm()
      }
    }
  }
}
</script>

<style>
.el-date-picker__header-label {
  display: inline-block !important;
}
</style>
<style lang="less" scoped>
/deep/ .el-form-item__label {
  text-align: left;
  font-size: 16px;
}
/deep/ .el-input .el-input__inner:focus {
  border-color: #dddddd !important;
}

/deep/ .el-input__inner {
  width: 433px;
}
.form {
  padding: 20px 16px 0 16px;
  border-bottom: 1px solid #eee;
}
</style>

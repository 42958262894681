<template>
  <div>
    <div class="vertical_title flex">个人信息</div>
    <PlayerInfoForm :isSubmit="isSubmit" @submitForm="submitForm"></PlayerInfoForm>
    <div class="btn" @click="saveClick">保存</div>
  </div>
</template>

<script>
import { objectKeys } from '@/utils/tools.js'
import { editInfo } from '@/service/api/user'
import PlayerInfoForm from '@/components/form/playerinfo.vue'
export default {
  components: { PlayerInfoForm },
  data() {
    return {
      isSubmit: false
    }
  },

  mounted() {},

  methods: {
    // 修改信息
    async editInfo(params) {
      const res = await editInfo(params)
      if (res.code == 1) {
        objectKeys(params, this.$store.state.userInfo)
        localStorage.setItem('user-info', JSON.stringify(this.$store.state.userInfo))
        this.$message.success('保存成功')
      } else {
        this.$message.error(res.msg)
      }
    },
    submitForm(params) {
      this.isSubmit = false
      if (JSON.stringify(params) == '{}') return
      console.log(params)
      this.editInfo(params)
    },
    saveClick() {
      this.isSubmit = true
    }
  }
}
</script>

<style lang="less" scoped>
.btn {
  width: 114px;
  height: 36px;
  color: #fff;
  cursor: pointer;
  margin-top: 29px;
  border-radius: 3px;
  line-height: 36px;
  text-align: center;
  margin-left: 150px;
  font-size: @font_size_16;
  background-color: @color_one;
}
</style>
